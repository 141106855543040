import React from "react";

// Componente de Paginación Reutilizable
const PaginationControls = ({
  page,
  pageSize,
  totalImages,
  handlePreviousPage,
  handleNextPage,
  handlePageSizeChange,
}) => {
  return (
    <>
      <div className="page-size-controls">
        <label htmlFor="pageSize">Imágenes por página:</label>
        <select id="pageSize" value={pageSize} onChange={handlePageSizeChange}>
          <option value={20}>20</option>
          <option value={35}>35</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
      <div className="pagination-controls">
        <button onClick={handlePreviousPage} disabled={page === 1}>
          Anterior
        </button>
        <span>
          Página {page}/{Math.ceil(totalImages / pageSize)}
        </span>
        <button
          onClick={handleNextPage}
          disabled={page * pageSize >= totalImages}
        >
          Siguiente
        </button>
      </div>
    </>
  );
};

export default PaginationControls;
