import React from 'react';

const RankTypeSelector = ({ rankTypes, selectedRankType, onRankTypeChange }) => {
  return (
    <div className="rank-type-selector">
      <label htmlFor="rankType" className="rank-type-label">
        Seleccione el criterio de búsqueda:
      </label>
      <select
        id="rankType"
        value={selectedRankType}
        onChange={(e) => {
          const selectedOption = e.target.options[e.target.selectedIndex];
          onRankTypeChange(e.target.value, selectedOption.text);
        }}
        className="rank-type-select"
      >
        {rankTypes.map((type) => (
          <option key={type.rank_type} value={type.rank_type}>
            {type.description}
          </option>
        ))
        }
      </select>
    </div>
  );
};

export default RankTypeSelector;