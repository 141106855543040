
const API_URL = process.env.REACT_APP_IMAGE_LOADER_BASE_URL;
const API_KEY = process.env.REACT_APP_APP_IMAGE_API_KEY;

export const getImages = async (page, pageSize) => {
  try {
    const response = await fetch(`${API_URL}/api/v1/image/?page=${page}&page_size=${pageSize}`, { 
      method: "GET",
      headers: {
      "Content-Type": "application/json",
      "Authorization": `Api-Key ${API_KEY}`
    }});
    const data = await response.json();
    const processedImages = data.results.map(item => ({
      id: item.id,
      url: `data:image/jpeg;base64,${item.base64_content}`,
      description: item.description
    }));
    
    return {
      images: processedImages,
      total: data.count 
    };
  } catch (error) {
    console.error('Error al cargar imágenes:', error);
    return { images: [], total: 0 };
  }
};

export const getRankTypes = async () => {
  try {
    const response = await fetch(`${API_URL}/api/v1/rank/types/`, { 
      method: "GET",
      headers: {
      "Content-Type": "application/json",
      "Authorization": `Api-Key ${API_KEY}`
    }});
    if (!response.ok){
      throw new Error(`Error en la solicitud: ${response.status} ${response.statusText}`);
    }
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const postRankImage = async (base64Image, rankType, imageCollectionIds=null, topK=null) => {
  let payload = {
    base64: base64Image,
    rank_type: rankType,
  };

  if (imageCollectionIds)
    payload.image_id_collection = imageCollectionIds;
  if (topK)
    payload.top_k = topK;

  try {
    const response = await fetch(`${API_URL}/api/v1/rank/image/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Api-Key ${API_KEY}`
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`Error en la solicitud: ${response.status} ${response.statusText}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error al enviar los datos:", error);
    throw error;
  }
};

export const postImageCollection = async (imageCollectionIds, page=null, pageSize=null) => {
  let payload = {
    image_id_collection: imageCollectionIds
  };
  if (!page)
    page = 1
  if (!pageSize)
    pageSize = imageCollectionIds.length

  try {
    const response = await fetch(`${API_URL}/api/v1/image/collection/?page=${page}&page_size=${pageSize}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Api-Key ${API_KEY}`
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`Error en la solicitud: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    const processedImages = data.results.map(item => ({
      id: item.id,
      url: `data:image/jpeg;base64,${item.base64_content}`,
      description: item.description
    }));
    
    return {
      images: processedImages,
      total: data.count 
    };
  } catch (error) {
    console.error("Error al enviar los datos:", error);
    throw error;
  }
};