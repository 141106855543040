import React, { useState, useEffect } from 'react';
import './App.css';
import ImageViewer from './components/ImageViewer';
import ImageList from './components/ImageList';
import RankTypeSelector from './components/RankTypeSelector';
import PaginationControls from './components/PaginationControls';
import {getImages, getRankTypes} from './services/imageService';
import {postRankImage, postImageCollection} from './services/imageService';

const App = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalImages, setTotalImages] = useState(0);
  const [pageSorted, setPageSorted] = useState(1);
  const [pageSizeSorted, setPageSizeSorted] = useState(20);
  const [totalImagesSorted, setTotalImagesSorted] = useState(0);
  const [userImage, setUserImage] = useState(null);
  const [rankTypes, setRankTypes] = useState(null);
  const [rankImages, setRankImages] = useState(null);
  const [rankImageIds, setRankImageIds] = useState(null);
  const [selectedRankType, setSelectedRankType] = useState(null);
  const [selectedRankDescription, setSelectedRankDescription] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const loadImages = async () => {
    const result = await getImages(page, pageSize);
    setImages(result.images);
    setTotalImages(result.total);
    const ranks = await getRankTypes()
    setRankTypes(ranks);
    
    if (rankImages){
      setIsLoading(true);
      try{
        const data = await postImageCollection(rankImageIds.image_id_collection, pageSorted, pageSizeSorted);
        setRankImages(data.images)
        console.log(data.images)
      } catch (error) {
        setRankImages(null)
        console.error('Error durante la operación:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    loadImages();
  }, [page, pageSize, pageSorted, pageSizeSorted]);

  const handleNextPage = () => {
    if (page * pageSize < totalImages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleNextPageSorted = () => {
    if (pageSorted * pageSizeSorted < totalImagesSorted) {
      setPageSorted((prevPageSorted) => prevPageSorted + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const handlePreviousPageSorted = () => {
    if (pageSorted > 1) {
      setPageSorted((prevPageSorted) => prevPageSorted - 1);
    }
  };

  const handlePageSizeChange = (event) => {
    setPageSize(Number(event.target.value));
    setPage(1);
  };

  const handlePageSizeChangeSorted = (event) => {
    setPageSizeSorted(Number(event.target.value));
    setPageSorted(1);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setUserImage(e.target.result);
      reader.readAsDataURL(file);
    }
  };

  const handleRankTypeChange = (newRankType, newRankDescription) => {
    setSelectedRankType(newRankType);
    setSelectedRankDescription(newRankDescription);
  };

  const handleImagesRank = async () => {
    setIsLoading(true);
    try{
      const rankIds = await postRankImage(userImage.split(',')[1], selectedRankType);
      setRankImageIds(rankIds);
      const rankImages = await postImageCollection(rankIds.image_id_collection, pageSorted, pageSizeSorted);
      setTotalImagesSorted(rankImages.total);
      setRankImages(rankImages.images)
    } catch (error) {
      setRankImages(null)
      console.error('Error durante la operación:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="App">
      <h1>Visor de Imágenes</h1>
      <div className="main-container">
        <div className="image-list-container">
          <PaginationControls
            page={page}
            pageSize={pageSize}
            totalImages={totalImages}
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
            handlePageSizeChange={handlePageSizeChange}
          />
          <div className="image-panel">
            <ImageList images={images} onImageSelect={setSelectedImage} />
          </div>
        </div>

        <div className="image-viewer-container">
          <div className="upload-section">
            <label htmlFor="upload" className="upload-label">
              Subir una imagen:
            </label>
            <input className="hidden-input" type="file" id="upload" onChange={handleImageUpload} />
          </div>
          <ImageViewer selectedImage={selectedImage} userImage={userImage} />
          {userImage && (
            <div class="rank-panel">
              <RankTypeSelector
                rankTypes={rankTypes}
                onRankTypeChange={handleRankTypeChange}
              />
              {isLoading && <div className="loading-spinner">🔎</div>}
              {!isLoading && <button disabled={!selectedRankType} onClick={handleImagesRank}>Ordenar</button>}
            </div>
          )}
        </div>

        <div className="image-panel-results">
          <label>Colección {`Ordenada ${selectedRankDescription? selectedRankDescription : ''}`}</label>
          <PaginationControls
            page={pageSorted}
            pageSize={pageSizeSorted}
            totalImages={totalImagesSorted}
            handlePreviousPage={handlePreviousPageSorted}
            handleNextPage={handleNextPageSorted}
            handlePageSizeChange={handlePageSizeChangeSorted}
          />
         {rankImages && ( <div className="image-panel">
            <ImageList images={rankImages} onImageSelect={setSelectedImage} />
          </div>)}
        </div>
      </div>
    </div>
  );
};

export default App;
