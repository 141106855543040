import React from 'react';

const ImageList = ({ images, onImageSelect }) => {
  return (
    <div className="image-list">
      {images.map((image, index) => (
        <img
          key={image.id}
          src={image.url}
          alt={image.description}
          className="thumbnail"
          onClick={() => onImageSelect(image.url)}
        />
      ))}
    </div>
  );
};


export default ImageList;