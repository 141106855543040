const ImageViewer = ({ selectedImage, userImage }) => {
  return (
    <div className="image-viewer">
      {userImage && (
        <div className="viewer-section">
          <div className="image-user-upload">
            <p>Imagen del usuario:</p>
            <img src={userImage} alt="Subida" />
          </div>
        </div>
      )}

      {selectedImage && (
        <div className="viewer-section">
          <div className="image-user-selected">
            <p>Imagen seleccionada:</p>
            <img src={selectedImage} alt="Seleccionada" />
          </div>  
        </div>
      )}
    </div>
  );
};

export default ImageViewer;